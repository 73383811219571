<template>
  <div class="contactListComplete">
    <LoadingPlaceholder v-if="loading" />
    <template v-else>
      <Grid
        :ref="kgm_ref"
        :style="{height: 'auto'}"
        :data-items="kgm_computedGridItems(contacts)"
        :columns="kgm_responsiveColumns()"
        :filterable="true"
        :filter="kgm_filter"
        :pageable="kgm_pagable"
        :page-size="kgm_take"
        :skip="kgm_skip"
        :take="kgm_take"
        :total="kgm_allGridItems(contacts)"
        :sortable="{
          allowUnsort: true,
          mode: kgm_sortMode
        }"
        :sort="kgm_sort"
        selected-field="selected"
        @rowclick="kgm_gridOnRowClick"
        @filterchange="kgm_gridFilterChange"
        @pagechange="kgm_gridPageChange"
        @sortchange="kgm_gridSortChange"
      >
        <template
          slot="metisLoginCell"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <div style="display: flex; justify-content: center;">
              <template>
                <font-awesome-icon
                  v-if="props.dataItem.keycloakRoles && (props.dataItem.keycloakRoles.includes('scope_staff') ||
                    props.dataItem.keycloakRoles.includes('scope_customer'))"
                  v-tooltip="props.dataItem.keycloakRoles.toString()"
                  icon="door-open"
                  size="2x"
                />
                <font-awesome-icon
                  v-else-if="props.dataItem.isInKeycloak && (!props.dataItem.keycloakRoles || (props.dataItem.keycloakRoles &&
                    !(props.dataItem.keycloakRoles.includes('scope_staff') ||
                      props.dataItem.keycloakRoles.includes('scope_customer'))))"
                  v-tooltip="props.dataItem.keycloakRoles ? props.dataItem.keycloakRoles.toString() : 'no roles'"
                  icon="door-closed"
                  size="2x"
                  style="color: blue;"
                />
                <font-awesome-icon
                  v-else-if="!props.dataItem.isInKeycloak"
                  icon="door-closed"
                  size="2x"
                  style="color: black;"
                />
              </template>
            </div>
          </td>
        </template>
        <template
          slot="nameTemplate"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <a
              :href="jiraHref(props.dataItem.id)"
              target="_blank"
              class="alt-primary-color" 
            >
              {{ props.dataItem.fullName }}
            </a>
          </td>
        </template>
        <div
          slot="nameFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="nameFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a name"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('nameFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="phoneFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="phoneFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a number"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('phoneFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="emailFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="emailFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a email"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('emailFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <template
          slot="optionsTemplate"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <div class="btn-group">
              <button
                class="btn btn-primary btn-sm"
                @click="openInstallationsModal(props.dataItem)"
              >
                <span>{{ $t('installations') }}</span>
              </button>
            </div>
          </td>
        </template>
      </Grid>
    </template>
    <SweetModal
      ref="installations"
      :title="$t('installations')"
      blocking
      class="overflowHidden"
    >
      <LoadingPlaceholder v-if="modalLoading" />
      <template v-else>
        <div
          style="overflow: auto; max-height: 450px;"
        >
          <table class="table">
            <thead>
              <tr>
                <th scope="col">
                  ID
                </th>
                <th scope="col">
                  Name
                </th>
                <th scope="col">
                  {{ $t('installationDetailOperationView.note') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in installationIdsAndNames"
                :key="`id-listItem-${ index }`"
              >
                <td>
                  <router-link
                    :to="{ path: '/installation/' + item.jiraIssueKey + '/operation/q/' + selectedContact.fullName }"
                    active-class="active"
                    tag="a"
                    target="_blank"
                  >
                    <span>{{ item.jiraIssueKey }}</span>
                  </router-link>
                </td>
                <td>
                  <span>
                    {{ item.summary }}
                  </span>
                </td>
                <td>
                  <input
                    v-model="item.note"
                    v-focus
                    :placeholder="$t('installationDetailOperationView.insertNote')"
                    :class="['form-control m-input m-input--air']"
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </template>
      <button
        slot="button"
        class="btn btn-primary mr-3"
        :disabled="disabledAddNote"
        @click="createNotes()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="plus"
        />{{ $t('installationDetailOperationView.addNote') }}
      </button>
      <button
        slot="button"
        class="btn btn-secondary float-right"
        @click="$refs.installations.close()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="times"
        />{{ $t('cancel') }}
      </button>
      <div class="clearfix" />
    </SweetModal>
  </div>
</template>

<script>
import { kendoGridMixin } from '@/mixins/kendoGridMixin.js';
import { SweetModal } from 'sweet-modal-vue';
import { urlParseMixin } from '@/mixins/urlParseMixin';
import { mapGetters } from 'vuex';
import { dateTimeMixin } from '@/mixins/dateTimeMixin.js';
import historyAdapter from '@/mixins/historyAdapter.js';

export default {
  name: "ContactListComplete",
  components: {
    SweetModal
  },
  mixins: [
    kendoGridMixin,
    urlParseMixin,
    dateTimeMixin,
    historyAdapter
  ],
  data () {
    return {
      loading: true,
      modalLoading: true,
      contacts: null,
      installationIdsAndNames: null,
      selectedContact: null,
      kgm_filter: {
        logic: "and",
        filters: [
          {
            field: "fullName",
            operator: "contains",
            value: ""
          },
          {
            field: "phone",
            operator: "contains",
            value: this.$route.params.phone != null ? this.$route.params.phone : ""
          },
          {
            field: "email",
            operator: "contains",
            value: ""
          }
        ]
      },
      kgm_columns: [
        {
          field: 'fullName',
          filterable: true,
          title: 'Name',
          filterCell: 'nameFilter',
          cell: 'nameTemplate'
        },
        {
          field: 'phone',
          filterable: true,
          title: this.$t('phone'),
          filterCell: 'phoneFilter'
        },
        {
          field: 'email',
          filterable: true,
          title: this.$t('email'),
          filterCell: 'emailFilter',
          hideOn: ['xsDown']
        },
        {
          field: 'metisLogin',
          filterable: false,
          title: `Metis Login`,
          cell: 'metisLoginCell',
          hideOn: ['xsDown']
        },
        {
          filterable: false,
          title: this.$t('installations'),
          cell: 'optionsTemplate',
          width: '130px'
        }
      ]
    }
  },
  computed: {
     ...mapGetters(['store_getJiraUrl']),
     disabledAddNote () {
       return this.installationIdsAndNames && !this.installationIdsAndNames.filter(x => x.note != null).map(x => x.note.length).some((e) => e > 0);
     }
  },
  created () {
    if(this.$route.params.phone) {
      var text = "Search for " + this.$route.params.phone + " requested at "+ this.dateTime_dateTimeSeconds(this.moment());
      this.$emit('showCall', text);
    }
    this.getData();
  },
  methods: {
    jiraHref: function (id) {
      return this.urlParseMixin_combine(this.store_getJiraUrl, `browse/${ id }`);
    },
    openInstallationsModal (contact) {
      this.selectedContact = contact;
      this.getInstallationIds(contact);
      this.$refs.installations.open();
    },
    //#region API-calls
    getName (first, last) {      
      if(first != null & last != null) {
        return first + ' ' + last;
      }
      else if(first != null && !last) {
        return first;
      }
      else if(!first && last != null) {
        return last;
      }

      return '';
    },
    createNotes () {
      this.modalLoading = true;
      this.installationIdsAndNames.forEach(e => {
        if(e.note) {
          let text = "Customer: " + this.selectedContact.fullName + " \n ------------------ \n Note: " + e.note;
          this.sendHistoryComment(e.jiraIssueKey, text, "Customer Contacted Us");
        }
      });
      this.modalLoading = false;
      this.$refs.installations.close()
    },
    getInstallationIds (contact) {
      this.installationIdsAndNames = null;
      this.modalLoading = true;
      this.axios.post(`/Issue/GetContactInstallationIdsAndNames`, contact)
        .then((response) => {
          if (response.data) {
            this.installationIdsAndNames = response.data;
          }
        })
        .finally(() => {
          this.modalLoading = false;
        });
    },
    getData () {
      this.loading = true;
      this.axios.get(`/Issue/GetAllContacts`)
      .then(response => {
        this.contacts = response.data;
        this.contacts.forEach(e => {
          e.fullName = this.getName(e.firstname, e.surname);
          e.phone = e.phone != null ? e.phone.replaceAll(" ", "") : null;
        });
      })
      .finally(() => {
        this.loading = false;
      });
    }
  }
}
</script>
<style>
.contactListComplete .k-grid table tr:hover td {
  background :rgb(219, 219, 219) !important;
  cursor: pointer !important;
}
</style>