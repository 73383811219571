export default {
  methods: {
    sendHistoryComment (installationId, message, type, recipient) {

      let historyComment = {
        'InstallationId': installationId,
        'Message': message,
        'Type': type
      };

      if(recipient) {
        historyComment.recipient = recipient;
      }

      return this.axios.post('/Installation/AddInstallationComment', historyComment);
    },
  }
}